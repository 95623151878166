export const ApiOptions = {
    method: '',
    body: '',
    headers: ''
}

export const TransactionParams = {
    reference: '',
    start: '',
    end: '',
    transaction_type: null,
    account_id: null,
    status: null,
    status_list: [],
    index: 0,
    size: 10
}

export const WithdrawConfigParams = {
    start: null,
    end: null,
    status: null,
    index: '0',
    size: 10
}

// OFACParams = {
export const OFACParams = {
    ofac_details_id: '',
    reference: '',
    status: '',
    start: '',
    end: '',
    index: 0,
    size: 10
}

// Pending Payment Transactions
export const PendingTransactionParams = {
    reference: '',
    start: '',
    end: '',
    transaction_type: null,
    account_id: null,
    status: null,
    exclude_status_list: '0,99',
    source: null,
    index: 0,
    size: 10
}

export const returnParams = {
    start: '',
    end: '',
    index: 0,
    size: 10
}

// WebHook Creation Params
export const CreateWebHookParams = {
    webhookurl: '',
    post_json: false
}

// Recipient Params
export const RecipientParams = {
    counterparty_id: '',
    account_name: '',
    total: '',
    count: '',
    is_international: null,
    sortOrder: 'desc',
    sortBy: '',
    index: 0,
    size: 10
}

// Payment List Params
export const PaymentListParams = {
    instrument_type: null,
    payment_instrument_id: null,
    status: null,
    status_list: '1,-1,2',
    total: '',
    count: '',
    sortOrder: 'asc',
    sortBy: '',
    index: '0',
    size: 10
}

// Invoice List params
export const InvoiceListParams = {
    invoice_number: null,
    status: null,
    total: '',
    count: '',
    sortOrder: 'desc',
    sortBy: '',
    index: 0,
    size: 10
}

// QB History Params
export const qbParams = {
    start_date: null,
    end_date: null,
    index: 0,
    size: 10
}

// user Parsms
export const userParams = {
    first_name: null,
    last_name: null,
    email: null,
    index: 0,
    size: 10
}

// Recurring Params
export const recurringParams = {
    start: null,
    end: null,
    reference: null,
    transaction_type: null,
    isRecurring: null,
    status: null,
    index: 0,
    size: 10
}

// commission History
export const CommissionParams = {
    start: null,
    end: null,
    transactionReference: null,
    count: '',
    sortOrder: 'asc',
    sortBy: '',
    index: '0',
    size: 10
}

export const PageSizeParams = {
    index: '0',
    size: 10
}