import { useEffect } from "react"

export const SessionPage = () => {

    useEffect(() => {
        sessionStorage.clear();
    },[]);

    return (
        <div className="main-container">
            <div className="error-container" style={{textAlign: 'center'}}>
                <img src="https://cdn-icons-png.flaticon.com/512/6606/6606292.png" style={{width: 200 , marginBottom: 32}} alt=""></img>
                <h1 style={{textAlign: 'center', color: 'white', marginTop: 32, marginBottom: 32 , fontSize: 48}}>Session timed out!</h1>
                <p>Your session ended. Try signing in again.</p>
            </div>
        </div>
    )
}
